export const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "email",
    numeric: true,
    disablePadding: false,
    label: "Email",
  },
  {
    id: "contact",
    numeric: true,
    disablePadding: false,
    label: "Contact",
  },
  {
    id: "verified",
    numeric: true,
    disablePadding: false,
    label: "Verified",
  },
  {
    id: "subscribed",
    numeric: true,
    disablePadding: false,
    label: "Subscribed",
  },
];
